html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

#container {
  height: 100%
}

.App {
  text-align: center;
  background-color: "#E6E6FA";
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: lightgray;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
  -webkit-border-radius: 50px;
}
::-webkit-scrollbar-corner {
  background-color: gray;
}


.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}


